import React from 'react';
import {connect} from 'react-redux'
import {setInitUrl, userSignOut, userSignOutRemember} from 'actions/Auth';
import {Link} from 'react-router-dom';

class UserInfo extends React.Component {
    render() {
        var role = 'indéfini';
        switch (this.props.authUser.data.role) {
            case 'ROLE_ADMIN':
                role = 'Administrateur';
                break;
            case 'ROLE_USER':
                role = 'Utilisateur';
                break;
        }

        return (
            <div>
                <div className='user-profile'>
                        <div className='user-detail ml-2'>
                            <h4 className='mb-0'>{this.props.authUser.data.first_name} {this.props.authUser.data.last_name.toUpperCase()}</h4>
                            <small>{role}</small>
                        </div>
                </div>
                    <Link className='dropdown-item text-muted' to='/app/utilisateur/profil'>
                        <i className='zmdi zmdi-face zmdi-hc-fw mr-1'/>
                        Profil
                    </Link>
                    <a className='dropdown-item text-muted' href='javascript:void(0)' onClick={() => {
                        this.props.userSignOut()
                    }}>
                        <i className='zmdi zmdi-sign-in zmdi-hc-fw mr-1'/>
                        Déconnexion
                    </a>
            </div>
        );
    }
}

const mapStateToProps = ({auth}) => {
    const {authUser, initURL, compte, urlEnCours} = auth;
    return {authUser, initURL, compte, urlEnCours}
};

export default connect(mapStateToProps, {userSignOut})((UserInfo));



