import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import 'react-big-calendar/lib/less/styles.less';
import 'react-toggle-switch/dist/css/switch.min.css';
import 'rc-drawer/assets/index.css';
import 'styles/bootstrap.scss'
import 'styles/app.scss';
import 'styles/app-rtl.scss';
import AppLocale from '../lngProvider';

import MainApp from 'app/index';
import SignIn from './SignIn';
import LockScreen from './LockScreen';
import {setInitUrl, updateNotification} from '../actions/Auth';
import asyncComponent from 'util/asyncComponent';
import {userSignOutRemember, premiereConnection} from 'actions/Auth';
import ForgotPassword from './ForgotPassword';
import cookie from 'react-cookies';
import 'react-toastify/dist/ReactToastify.css';


const RestrictedRoute = ({component: Component, rest, authUser}) =>
    <Route
        {...rest}
        render={props =>
            authUser
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/signin',
                        state: {from: props.location}
                    }}
                />}
    />;

class App extends Component {

    constructor(props) {
        super(props);
        //step 1

        this.handleClick = this.handleClick.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.timeoutTimer = null;
        this.startTimer();

    }

    componentDidMount(){
        this.props.updateNotification();
    }

    startTimer() {

        if (this.props.authUser !== null) {
            if (!(this.props.history.location.pathname === '/signin')) {
                if (!(this.props.history.location.pathname === '/lockscreen')) {
                    if (!(this.props.history.location.pathname === '/forgotpassword')) {
                        //console.log("this.props.authUser", this.props.authUser);
                        cookie.save('token', this.props.authUser.data.currentAgency.id+"|"+this.props.authUser.data.token, {
                            path: '/',
                            maxAge: (this.props.tempsSession / 1000)
                        });
                        cookie.save('id', this.props.authUser.data.id, {
                            path: '/',
                            maxAge: (this.props.tempsSession / 1000)
                        });
                    }
                }
            }
        }

        this.timeoutTimer = setTimeout(function () {
            this.logout();
        }.bind(this), this.props.tempsSession);

    }

    logout() {
        if (!(this.props.history.location.pathname === '/signin')) {
            if (!(this.props.history.location.pathname === '/lockscreen')) {
                if (!(this.props.history.location.pathname === '/forgotpassword')) {
                    this.props.userSignOutRemember(this.props.location.pathname);
                }
            }
        }
    }

    clearTimeout(timeoutTimer) {
        clearTimeout(timeoutTimer);
    }

    handleClick() {
        this.clearTimeout(this.timeoutTimer);
        this.startTimer();
    }

    componentWillMount() {
        if (this.props.initURL === '') {
            this.props.setInitUrl(this.props.history.location.pathname);
        }
    }

    render() {
        const {initURL, premiereCo, match, location, locale, authUser, isDirectionRTL, compte, urlEnCours, notification} = this.props;

        {/*
        if(this.props.authUser === null && cookie.load('token') !== undefined && cookie.load('id') !== undefined && initURL === '' && premiereCo){
            console.log('cokiiii');
            return (<Redirect to={'/lockscreen'}/>);
        }
        */
        }

        if(cookie.load('token') !== undefined && cookie.load('id') !== undefined && initURL === '' && premiereCo){
            this.props.premiereConnection();
            return (<Redirect to={'/lockscreen'}/>);
        }

        if (location.pathname === '/') {
            if (authUser === null && compte === false) {
                //this.props.premiereConnection();
                return (<Redirect to={'/signin'}/>);
            } else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === '/lockscreen') {
                //this.props.premiereConnection();
                return (<Redirect to={'/app/dashboard'}/>);
            } else {
                //this.props.premiereConnection();
                return (<Redirect to={initURL}/>);
            }
        }

        if ((authUser !== null && compte) && location.pathname !== '/lockscreen') {
            //this.props.premiereConnection();
            return (<Redirect to={'/lockscreen'}/>);
        }
        if ((authUser === null && !compte) && location.pathname !== '/signin' && location.pathname !== '/forgotpassword' && location.pathname !== '/lockscreen') {
            //this.props.premiereConnection();
            return (<Redirect to={'/signin'}/>);
        }

        if (location.pathname === '/lockscreen' && !compte && urlEnCours !== '/lockscreen') {
            //this.props.premiereConnection();
            return (<Redirect to={`${urlEnCours}`}/>);
        }

        if(location.pathname === '/lockscreen' && authUser !== null && !compte){
            if(initURL === '/lockscreen'){
                return (<Redirect to={'/app/dashboard'}/>);
            }else{
                return (<Redirect to={`${initURL}`}/>);
            }


        }

        // for RTL Support
        if (isDirectionRTL) {
            document.body.classList.add('rtl')
        } else {
            document.body.classList.remove('rtl');
        }

        const currentAppLocale = AppLocale[locale.locale];
        return (
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >
                <div className='app-main' onMouseMove={this.handleClick}>
                    <Switch>
                        <RestrictedRoute path={`${match.url}app`} authUser={authUser}
                                         component={MainApp}/>
                        <Route path='/signin' component={SignIn}/>
                        <Route path='/lockscreen' component={LockScreen}/>
                        <Route path='/forgotpassword' component={ForgotPassword}/>
                        <Route
                            component={asyncComponent(() => import('components/Error404'))}/>
                    </Switch>
                </div>
            </IntlProvider>
        );
    }
}

const mapStateToProps = ({settings, auth}) => {
    const {locale, isDirectionRTL} = settings;
    const {authUser, initURL, compte, urlEnCours, tempsSession, premiereCo, notification} = auth;
    return {locale, isDirectionRTL, authUser, initURL, compte, urlEnCours, tempsSession, premiereCo, notification}
};

export default connect(mapStateToProps, {setInitUrl, userSignOutRemember, premiereConnection, updateNotification})((App));
