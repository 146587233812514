import React from 'react';
import {Button} from 'reactstrap';

const Footer = () => {
        return (
            <footer className='app-footer'>
                <div className='d-flex flex-row justify-content-between'>
                    <div>
                        <span> Copyright Slapp &copy; 2018</span>

                    </div>
                </div>
            </footer>
        );
    }
;

export default Footer;
