import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {
    SIGNIN_USER,
    SIGNOUT_USER,
    SIGNOUT_USER_REMEMBER,
    PREMIERE_CONNECTION,
    SIGNOUT_USER_EXPIRATION,
    UPDATE_NOTIFICATION, URLBASEAPI, SWITCH_USER
} from 'constants/ActionTypes';
import {
    showAuthMessage,
    userSignInSuccess,
    userSignOutSuccess,
    userSignOutSuccessRemember,
    updateNotificationSuccess
} from 'actions/Auth';

import axios from 'axios';
import cookie from 'react-cookies';

const signInUserWithEmailPasswordRequest = async (bodyFormData) => {
    return await axios.post(URLBASEAPI+'login',
        bodyFormData,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
        })
        .then(res => {
            return res;
        })
        .catch(error => error);
}



function* signInUserWithEmailPassword({payload}) {
    const {email, password} = payload;
    var bodyFormData = new FormData();
    bodyFormData.set('login', email);
    bodyFormData.set('pwd', password);
    try {
        const signInUser = yield call(signInUserWithEmailPasswordRequest, bodyFormData);

        if (signInUser.message === 'Request failed with status code 500') {
            yield put(showAuthMessage('Impossible de se connecter. Probleme de serveur'));
        } else {
            if (signInUser.data.success) {
                yield put(userSignInSuccess(signInUser.data));
            } else {
                yield put(showAuthMessage('Login ou mdp incorrect'));
            }
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

const switchUserToAgencyRequest = async (bodyFormData, agencyId) => {
    return await axios.post(URLBASEAPI+'switchagency/'+agencyId,
        bodyFormData,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
        })
        .then(res => {
            return res;
        })
        .catch(error => error);
}

function* switchUserToAgency({payload}) {
    const {id, token, agencyId} = payload;
    var bodyFormData = new FormData();
    bodyFormData.set('id', id);
    bodyFormData.set('token', token);
    try {
        const signInUser = yield call(switchUserToAgencyRequest, bodyFormData, agencyId);

        if (signInUser.message === 'Request failed with status code 500') {
            yield put(showAuthMessage('Impossible de se connecter. Probleme de serveur'));
        } else {
            if (signInUser.data.success) {
                yield put(userSignInSuccess(signInUser.data));
            } else {
                yield put(showAuthMessage('Accès impossible'));
            }
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signOut() {
    try {
        if (signInUser.message) {
            yield put(showAuthMessage(signInUser.message));
        } else {
            yield put(userSignOutSuccess(signInUser));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signOutRemember({payload}) {
    try {
        if (signInUser.message) {
            yield put(showAuthMessage(signInUser.message));
        } else {
            yield put(userSignOutSuccessRemember(signInUser, payload));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* premiereCo() {
}

function* decoTime() {
}

const updateNotifRequest = async (bodyFormData) => {
    return await axios.post(URLBASEAPI+'activities',
        bodyFormData,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then(res => res)
        .catch(error => error);
};


function* updateNotif() {

    var bodyFormData = new FormData();
    bodyFormData.set('id', cookie.load('id'));
    bodyFormData.set('token', cookie.load('token'));

    try {
        const notifRequest = yield call(updateNotifRequest, bodyFormData);

        if (notifRequest.data.success) {
                yield put(updateNotificationSuccess(notifRequest.data.data));

            } else {
                yield put(updateNotificationSuccess(notifRequest.data.success));
            }

    } catch (error) {
        yield put(showAuthMessage(error));
    }

}

export function* switchAgencyUser() {
    yield takeEvery(SWITCH_USER, switchUserToAgency);
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export function* signOutUserRemember() {
    yield takeEvery(SIGNOUT_USER_REMEMBER, signOutRemember);
}

export function* premiereConnection() {
    yield takeEvery(PREMIERE_CONNECTION, premiereCo);
}

export function* userSignOutExpiration() {
    yield takeEvery(SIGNOUT_USER_EXPIRATION, decoTime);
}

export function* updateNotification() {
    yield takeEvery(UPDATE_NOTIFICATION, updateNotif);
}

export default function* rootSaga() {
    yield all([
        fork(switchAgencyUser),
        fork(signInUser),
        fork(signOutUser),
        fork(signOutUserRemember),
        fork(premiereConnection),
        fork(userSignOutExpiration),
        fork(updateNotification)
    ]);
}
