import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Button,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    ListGroup,
    ListGroupItem, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import {COLLAPSED_DRAWER, FIXED_DRAWER, VERTICAL_NAVIGATION, INSIDE_THE_HEADER} from 'constants/ActionTypes';
import SearchBox from 'components/SearchBox';
import AppNotification from '../AppNotification/index';
import CardHeader from 'components/dashboard/Common/CardHeader/index';
import {switchLanguage, toggleCollapsedNav} from 'actions/Setting';
import UserInfo from 'components/UserInfo';
import Menu from 'components/Header/Menu';
import {URLBASE} from "constants/ActionTypes";
import {userSwithAgency} from "actions";
import cookie from "react-cookies";
//import iconHulk from "../../assets/images/icon-hulk.png";

class Header extends React.Component {


    onAppNotificationSelect = () => {
        this.setState({
            appNotification: !this.state.appNotification
        })
    };

    onUserInfoSelect = () => {
        this.setState({
            userInfo: !this.state.userInfo
        })
    };

    onSearchBoxSelect = () => {
        this.setState({
            searchBox: !this.state.searchBox
        })
    };

    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    constructor() {
        super();
        this.state = {
            searchBox: false,
            searchText: '',
            mailNotification: false,
            langSwitcher: false,
            appNotification: false,
            userInfo: false,
            modalAgency: false
        }
    }

    updateSearchText(evt) {
        this.setState({
            searchText: evt.target.value,
        });
    }

    switchAgency = (agencyId) => {
        this.setState({
            modalAgency: false
        });
        this.props.userSwithAgency ({
            id: cookie.load('id'),
            token:  cookie.load('token'),
            agencyId: agencyId
        });
    };

    toggleAgencyModal = () => {
        this.setState({
            modalAgency: !this.state.modalAgency
        });
    };

    render() {
        const {drawerType, locale, navigationStyle, horizontalNavPosition} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-flex d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-flex' : 'd-none';

        return (
            <div className='app-main-header'>
                <div className='d-flex app-toolbar justify-content-between align-items-center'>
                    <div>
                        <span className={`jr-menu-icon pointer ${drawerStyle}`}
                              onClick={this.onToggleCollapsedNav}>
                            <span className='menu-icon'/>
                        </span>
                    </div>

                    <img src={"/icon-hulk.png"} style={{maxHeight: 40}}/>

                    <ul className='header-notifications list-inline'>
                        <li className='list-inline-item app-tour'>
                            {this.props.authUser.data.agencies.length > 0 ?
                                <span style={{cursor: "pointer"}} className={"text-primary"} onClick={() => this.setState({modalAgency: true})}>{this.props.authUser.data.currentAgency.name}</span>
                                :
                                this.props.authUser.data.currentAgency.name
                            }
                        </li>
                        <li className='list-inline-item app-tour'>
                            <Dropdown
                                className='quick-menu'
                                isOpen={this.state.appNotification}
                                toggle={this.onAppNotificationSelect.bind(this)}>

                                <DropdownToggle
                                    className='d-inline-block'
                                    tag='span'
                                    data-toggle='dropdown'>
                                    <span className='icon-btn size-30'>
                                        <i className='zmdi zmdi-notifications-active zmdi-hc-lg'/>
                                    </span>
                                </DropdownToggle>

                                <DropdownMenu right>
                                    <CardHeader styleName='align-items-center'
                                                heading='Activités'/>
                                    <AppNotification/>
                                </DropdownMenu>
                            </Dropdown>
                        </li>

                        <li className='list-inline-item user-nav'>
                            <Dropdown
                                className='quick-menu'
                                isOpen={this.state.userInfo}
                                toggle={this.onUserInfoSelect.bind(this)}>

                                <DropdownToggle
                                    className='d-inline-block'
                                    tag='span'
                                    data-toggle='dropdown'>
                                    <span className='icon-btn size-30'>
                                        <i className='zmdi zmdi-account zmdi-hc-lg'/>
                                    </span>
                                </DropdownToggle>

                                <DropdownMenu right>

                                    <UserInfo/>
                                </DropdownMenu>
                            </Dropdown>

                        </li>
                    </ul>
                </div>

                <Modal style={{maxHeight: 150, width: 300}} isOpen={this.state.modalAgency} toggle={() => this.toggleAgencyModal()}>
                    <ModalHeader>Changer d'agence</ModalHeader>
                    <ModalBody>
                        <div>
                            <h5><strong>Agence principale</strong></h5>

                            <ListGroup>
                                <ListGroupItem>
                                    <div className="pointer custom-control custom-radio" key={this.props.authUser.data.franchisee.id}>
                                        <input type="radio" id={this.props.authUser.data.franchisee.id}
                                               className="custom-control-input"
                                               onChange={() => {this.switchAgency(this.props.authUser.data.franchisee.id)}}
                                               checked={this.props.authUser.data.franchisee.id === this.props.authUser.data.currentAgency.id} value={this.props.authUser.data.franchisee.id}/>
                                        <label className="custom-control-label" htmlFor={this.props.authUser.data.franchisee.id}>{this.props.authUser.data.franchisee.name}</label>
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </div>
                        {this.props.authUser.data.agencies.length > 0 &&
                            <div className={"mt-3"}>
                                <h5><strong>Autres agences</strong></h5>

                                <ListGroup>
                                    <ListGroupItem>
                                        {this.props.authUser.data.agencies.map((agency, agency_key) => {
                                            return (
                                                <div className="pointer custom-control custom-radio" key={agency.id}>
                                                    <input type="radio" id={agency.id} name="customRadio"
                                                           className="custom-control-input"
                                                           onChange={() => {this.switchAgency(agency.id)}}
                                                           checked={agency.id === this.props.authUser.data.currentAgency.id} value={agency.id}/>
                                                    <label className="custom-control-label" htmlFor={agency.id}>{agency.name}</label>
                                                </div>
                                            );
                                        })}
                                    </ListGroupItem>
                                </ListGroup>
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" className="jr-btn bg-primary" onClick={() => this.toggleAgencyModal()}>Fermer</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }

}

const mapStateToProps = ({settings, auth}) => {
    const {authUser} = auth;
    const {drawerType, locale, navigationStyle, horizontalNavPosition} = settings;
    return {drawerType, locale, navigationStyle, horizontalNavPosition, authUser}
};

export default withRouter(connect(mapStateToProps, {userSwithAgency, toggleCollapsedNav, switchLanguage})(Header));
