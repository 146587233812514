import React from 'react';
import moment from 'moment';

function ChoixType(type){
    switch (type){
        case 'customer' :
            return(
                <i className={'zmdi zmdi-account-box zmdi-hc-fw'} />
            );
            break;
        case 'note' :
            return(
                <i className={'zmdi zmdi-comment-more zmdi-hc-fw'} />
            );
            break;
        case 'estimate' :
            return(
                <i className={'zmdi zmdi-time-interval zmdi-hc-fw'} />
            );
        case 'invoice' :
            return(
                <i className={'zmdi zmdi-case zmdi-hc-fw'} />
            );
        case 'order' :
            return(
                <i className={'zmdi zmdi-truck zmdi-hc-fw'} />
            );
            break;
    }
}

const NotificationItem = ({ notification }) => {
    const { type, date, message } = notification;

    const newDate = moment(date);

    return (

        <li className='media'>
            <div className='media-body align-self-center'>
                <p className='sub-heading mb-0'>{message}</p>
                 <span className='meta-date'>{ChoixType(type)}<small>{newDate.format('[LE] DD/MM/YYYY [À] HH:mm')}</small></span>
            </div>
        </li>
    );
};

export default NotificationItem;
