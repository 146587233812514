import React from 'react';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import {hideMessage, showAuthLoader, userSignIn, userSignOut} from 'actions/Auth'
import { Button } from 'reactstrap';

import CircularProgress from 'components/CircularProgress'
import cookie from 'react-cookies';
import axios from 'axios/index';
import {userSignOutExpiration} from '../sagas/Auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {URLBASE, URLBASEAPI} from "constants/ActionTypes";

class LockScreen extends React.Component {

    constructor(props) {
        super(props);
        if(this.props.authUser !== null){
            this.state = {
                email: props.authUser.data.email,
                password: ''
            }
        }else{
            this.state = {
                password: '',
                email: ''
            }
        }
    }
    notifySuccessLogin = (message) => {
        toast.success(message);
    };

    notifyErrorLogin = (message) => {
        toast.error(message);
    };

    notifyError = () => {
        toast.error('Session expirée, veuillez vous reconnecter');
    };

    componentDidUpdate() {
        if (this.props.showMessage) {
            setTimeout(() => {
                this.props.hideMessage();
            }, 1000);
        }
    }

    handleKeyPress = (event) => {

        if(event.key === 'Enter'){
            const {
                email,
                password
            } = this.state;
            if(email === ''){
                this.Connection(password);
            }else{
                this.props.showAuthLoader ();
                this.props.userSignIn ({ email, password });
            }

        }
    };

    async Connection(password) {

        if (cookie.load('id') !== undefined && cookie.load('token') !== undefined){
            var bodyFormData = new FormData();
            bodyFormData.set('id', cookie.load('id'));
            bodyFormData.set('token', cookie.load('token'));
            bodyFormData.set('pwd', password);

            await axios.post(URLBASEAPI+'unlock',
                bodyFormData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                })
                .then(res => {
                    if(res.data.success){
                        this.notifySuccessLogin('Connection réussie.');
                        const email = res.data.data.email;
                        this.props.userSignIn({ email, password });
                    }else{
                        this.notifyErrorLogin('Mot de passe invalide.');
                    }
                })
                .catch(error => error);
        }else{
            this.notifyError();
            this.props.userSignOut();
        }

    }

    render() {

        const {
            email,
            password
        } = this.state;
        const { showMessage, loader, alertMessage } = this.props;

        if(this.props.authUser === null){
            return(
                    <div
                        className='login-container d-flex w-100 justify-content-center align-items-center animated slideInUpTiny animation-duration-3'>
                        <div className='login-content text-center'>
                            <div className='login-header'>
                                <Link className='app-logo' title='Jambo' to='/signin' onClick={() => {
                                    this.props.userSignOut()
                                }}>
                                    <img src={URLBASE+'bundles/app/assets/medias/i2s_logo_105_36.png'} alt='jambo' title='jambo' />
                                </Link>
                            </div>

                            <div className='mb-4'>
                                <h3></h3>
                                <p>Entrez votre mot de passe</p>
                            </div>
                            <form method='get'>
                                <div className='form-group mb-3'>
                                    <input
                                        type='password'
                                        placeholder='Mot de passe'
                                        onChange={(event) => this.setState({ password: event.target.value })}
                                        value={password}
                                        className='form-control form-control-lg'
                                        onKeyPress={this.handleKeyPress}
                                    />
                                </div>


                                <div className='mb-2'>
                                    <Button onClick={() => {
                                        this.Connection(password);
                                    }}
                                            color='primary' className='text-uppercase'>
                                        Dévérouiller
                                    </Button>
                                </div>
                            </form>
                            <div>
                                <Link className='right-arrow' to='/signin' onClick={() => {
                                    this.props.userSignOut()
                                }}>Utiliser un autre compte</Link>
                            </div>
                        </div>
                        {
                            loader &&
                            <div className='loader-view'>
                                <CircularProgress />
                            </div>
                        }
                        {showMessage && NotificationManager.error(alertMessage)}
                        <NotificationContainer />
                    </div>

                )
        }else{
            return (
                    <div
                        className='login-container d-flex justify-content-center w-100 align-items-center animated slideInUpTiny animation-duration-3'>
                        <div className='login-content text-center'>
                            <div className='login-header'>
                                <Link className='app-logo' title='Jambo' to='/signin' onClick={() => {
                                    this.props.userSignOut()
                                }}>
                                    <img src={URLBASE+'bundles/app/assets/medias/i2s_logo_105_36.png'} alt='jambo' title='jambo' />
                                </Link>
                            </div>

                            <div className='mb-4'>
                                <h3>{this.props.authUser.data.first_name + ' ' + this.props.authUser.data.last_name}</h3>
                                <p>Entrez votre mot de passe</p>
                            </div>
                            <form method='get'>
                                <div className='form-group mb-3'>
                                    <input
                                        type='password'
                                        placeholder='Mot de passe'
                                        onChange={(event) => this.setState({ password: event.target.value })}
                                        value={password}
                                        className='form-control form-control-lg'
                                        onKeyPress={this.handleKeyPress}
                                    />
                                </div>


                                <div className='mb-2'>
                                    <Button onClick={() => {
                                        this.props.showAuthLoader();
                                        this.props.userSignIn({ email, password });
                                    }}
                                            color='primary' className='text-uppercase'>
                                        Dévérouiller
                                    </Button>
                                </div>
                            </form>
                            <div>
                                <Link className='right-arrow' to='/signin' onClick={() => {
                                    this.props.userSignOut()
                                }}>Utiliser un autre compte</Link>
                            </div>
                        </div>
                        {
                            loader &&
                            <div className='loader-view'>
                                <CircularProgress />
                            </div>
                        }
                        {showMessage && NotificationManager.error(alertMessage)}
                        <NotificationContainer />
                    </div>

            )
        }

    }
}

const mapStateToProps = ({ auth }) => {
    const { loader, alertMessage, showMessage, authUser } = auth;
    return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
    userSignIn,
    hideMessage,
    showAuthLoader,
    userSignOut,
    userSignOutExpiration
})(LockScreen);

