import React from 'react';
import {MINI_DRAWER, URLBASE} from 'constants/ActionTypes';
import { Link } from 'react-router-dom';

const SidenavLogo = ({drawerType}) => {

    const showMini = drawerType.includes(MINI_DRAWER);

    return (
        <div className='sidebar-header d-flex align-items-center'>
            {showMini ?
                <Link to={'/'}>
                <div className='mini-logo'>
                    <img className='mini-logo-img' alt='...' src='http://via.placeholder.com/32x32'/>
                    <img className='mini-logo-img-hover' alt='...' src={URLBASE+'bundles/app/assets/medias/i2s_logo_105_36.png'}/>
                </div> </Link>: <div> <Link to={'/'}> <img alt='...' src={URLBASE+'bundles/app/assets/medias/i2s_logo_105_36.png'}/> </Link></div>
            }
        </div>
    );
};

export default SidenavLogo;
