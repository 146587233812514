import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
    hideMessage,
    showAuthLoader,
    userSignIn,
} from 'actions/Auth';
import CircularProgress from 'components/CircularProgress';
import {URLBASE} from "constants/ActionTypes";


class SignIn extends React.Component {
    constructor () {
        super ();
        this.state = {
            email: '',
            password: ''
        }
    }

    componentDidUpdate () {
        if (this.props.showMessage) {
            setTimeout (() => {
                this.props.hideMessage ();
            }, 1000);
        }
        if (this.props.authUser !== null) {
            this.props.history.push ('/');
        }
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            const {
                email,
                password
            } = this.state;
            this.props.showAuthLoader ();
            this.props.userSignIn ({ email, password });
        }
    };

    render () {
        const {
            email,
            password
        } = this.state;
        const { showMessage, loader, alertMessage } = this.props;
        return (
            <div
                className='app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3'>
                <div className='app-login-main-content'>

                    <div className='app-logo-content d-flex align-items-center justify-content-center'>
                        <Link className='logo-lg' to='/' title='Jambo'>
                            <img src={URLBASE+'bundles/app/assets/medias/i2s_logo_177_65.png'} alt='jambo' title='jambo' />
                        </Link>
                    </div>

                    <div className='app-login-content'>
                        <div className='app-login-header mb-4'>
                            <h1>I2S Aménagements</h1>
                        </div>

                        <div className='app-login-form'>
                            <form>
                                <div className='form-group mb-3'>
                                    <input
                                        placeholder='Email'
                                        onChange={(event) => this.setState ({ email: event.target.value })}
                                        defaultValue={email}
                                        className='form-control form-control-lg'
                                        onKeyPress={this.handleKeyPress}
                                    />
                                </div>

                                <div className='form-group mb-3'>
                                    <input
                                        type='password'
                                        placeholder='Password'
                                        onChange={(event) => this.setState ({ password: event.target.value })}
                                        defaultValue={password}
                                        className='form-control form-control-lg'
                                        onKeyPress={this.handleKeyPress}
                                    />
                                </div>

                                <div className='mb-3 d-flex align-items-center justify-content-between'>
                                    <Button onClick={() => {
                                        this.props.showAuthLoader ();
                                        this.props.userSignIn ({ email, password });
                                    }} color='primary' className='text-uppercase'>
                                        Connexion
                                    </Button>

                                    <Link to='/forgotpassword'>
                                        Mot de passe oublié ?
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
                {
                    loader &&
                    <div className='loader-view'>
                        <CircularProgress />
                    </div>
                }
                {showMessage && NotificationManager.error (alertMessage)}
                <NotificationContainer />
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const { loader, alertMessage, showMessage, authUser } = auth;
    return { loader, alertMessage, showMessage, authUser }
};

export default connect (mapStateToProps, {
    userSignIn,
    hideMessage,
    showAuthLoader
}) (SignIn);
