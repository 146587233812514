import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import {
    ROUTE_PROJECTS,
    ROUTE_OFFRE
} from 'constants/ActionTypes';


const Projects = () => (

    <div className='app-wrapper'>
        <Switch>
            <Redirect exact from={`${ROUTE_PROJECTS}`} to={`${ROUTE_OFFRE}/listedevis`}/>
            <Route path={`${ROUTE_PROJECTS}/:idproject`} component={asyncComponent(() => import('./routes/ProjectsOne'))}/>
            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Projects;
