import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import cookie from 'react-cookies';
import axios from 'axios/index';
import {Button} from 'reactstrap';
import {toast} from 'react-toastify';
import {URLBASE, URLBASEAPI} from "constants/ActionTypes";


class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            onChange: false,
            email: ''
        }
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.NewPassword();
        }
    };

    notifySuccessemail = (message) => {
        toast.success(message);
    };

    notifyErroremail = (message) => {
        toast.error(message);
    };

    async NewPassword() {

        var bodyFormData = new FormData();
        bodyFormData.set('id', cookie.load('id'));
        bodyFormData.set('token', cookie.load('token'));
        bodyFormData.set('email', this.state.email);


        await axios.post(URLBASEAPI+'forgotpwd',
            bodyFormData,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
            })
            .then(res => {
                if (res.data.success) {
                    this.notifySuccessemail(res.data.message);
                    this.setState({
                        onChange: !this.state.onChange
                    })
                } else {
                    this.notifyErroremail(res.data.message)
                    this.setState({
                        email: ''
                    })
                }
            })
            .catch(error => {
            });

    }

    render() {

        if (this.state.onChange) {
            return (<Redirect to={'/signin'}/>);
        }

        return (
            <div
                className='app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3'>
                <div
                    className='login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3'>
                    <div className='login-content'>
                        <div className='login-header text-center'>
                            <Link className='app-logo' to='/signin' title='Jambo'>
                                <img src={URLBASE+'bundles/app/assets/medias/i2s_logo_105_36.png'} alt='jambo' title='jambo'/>
                            </Link>
                        </div>

                        <div className='mb-2'>
                            <h2>Mot de passe oublié ?</h2>
                        </div>

                        <div className='login-form'>

                            <input
                                type='email'
                                placeholder='Votre Email'
                                className='mt-0 mb-4 form-control form-control-lg'
                                onChange={(event) => this.setState({email: event.target.value})}
                                onKeyPress={this.handleKeyPress}
                                value={this.state.email}
                            />

                            <div className='mb-2'>
                                <Button onClick={() => {
                                    this.NewPassword()
                                }}
                                        color='primary' className='text-uppercase'>
                                    Réinitialiser
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;

