import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS_REMEMBER,
    PREMIERE_CONNECTION,
    SIGNOUT_USER_EXPIRATION,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_USER
} from 'constants/ActionTypes';
import cookie from 'react-cookies'

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    authUser: null,
    compte: false,
    urlEnCours : '',
    tempsSession : 100000000,
    premiereCo : true,
    notification : null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_USER_SUCCESS: {
            console.log("update token", action.payload.data.currentAgency.id+"|"+action.payload.data.token);
            cookie.save(
                'token',
                action.payload.data.currentAgency.id+"|"+action.payload.data.token,
                {
                    path: '/',
                    maxAge: (state.tempsSession / 1000)
                }
            );

            cookie.save(
                'id',
                action.payload.data.id,
                {
                    path: '/',
                    maxAge: (state.tempsSession / 1000)
                }
            );
            return {
                ...state,
                loader: false,
                authUser: action.payload,
                compte: false
            }
        }
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }
        case UPDATE_USER: {
            return {
                ...state,
                authUser: action.payload
            }
        }
        case UPDATE_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                notification: action.payload
            }
        }
        case PREMIERE_CONNECTION: {
            return {
                ...state,
                premiereCo : false,
                urlEnCours : '/lockscreen'
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            cookie.remove('id', { path: '/' });
            cookie.remove('token', { path: '/' });
            return {
                ...state,
                authUser: null,
                initURL: '/',
                loader: false,
                compte: false,
                urlEnCours : ''
            }
        }
        case SIGNOUT_USER_SUCCESS_REMEMBER: {
            return {
                ...state,
                initURL: '/',
                loader: false,
                compte: true,
                urlEnCours : action.paryloadUrl
            }
        }

        case SIGNOUT_USER_EXPIRATION: {
            return {
                ...state,
                authUser: null,
                initURL: '/',
                loader: false,
                compte: false,
                urlEnCours : ''
            }
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        default:
            return state;
    }
}
