import React, {Component} from 'react';
import NotificationItem from './NotificationItem';
import CustomScrollbars from 'util/CustomScrollbars';
import {connect} from 'react-redux';


class AppNotification extends Component {

    constructor(props){
        super(props);
        this.state = {
            data : []
        }
    }

    render(){

        if(!this.props.notification){
            return(
                <CustomScrollbars className='messages-list scrollbar' style={{height: 280}}>
                    <ul className='list-unstyled'>
                        {this.state.data.map((notification, index) => <NotificationItem key={index}
                                                                                        notification={notification}/>)
                        }
                    </ul>
                </CustomScrollbars>
                )
        }else{
            return(
                <CustomScrollbars className='messages-list scrollbar' style={{height: 280}}>
                    <ul className='list-unstyled'>
                        {this.props.notification.map((notification, index) => <NotificationItem key={index}
                                                                                                notification={notification}/>)
                        }
                    </ul>
                </CustomScrollbars>
                )
        }
    }
}

const mapStateToProps = ({auth}) => {
    const {notification} = auth;
    return {notification}
};

export default connect(mapStateToProps)(AppNotification);

