import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import {
    ROUTE_OFFRE
} from 'constants/ActionTypes';
import {connect} from "react-redux";


class Offres extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authBilling: false
        };
    }

    componentDidMount() {
        if (this.props && this.props.authUser !== undefined) {
            if (this.props.authUser.success === true) {
                if (this.props.authUser.data.role !== undefined && this.props.authUser.data.role === 'ROLE_ADMIN') {
                    this.setState({authBilling: true});
                }
            }
        }
    }

    render() {
        return (
            <div className='app-wrapper'>
                {this.state.authBilling
                    ?
                    (
                        <Switch>
                            <Redirect exact from={`${ROUTE_OFFRE}/`} to={`${ROUTE_OFFRE}/adddevis`}/>
                            <Route path={`${ROUTE_OFFRE}/adddevis`} component={asyncComponent(() => import('./routes/CreerDevis'))}/>
                            <Route path={`${ROUTE_OFFRE}/editdevis/:devis`} component={asyncComponent(() => import('./routes/EditDevis'))}/>
                            <Route path={`${ROUTE_OFFRE}/consultdevis/:devis`} component={asyncComponent(() => import('./routes/ConsultDevis'))}/>
                            <Route path={`${ROUTE_OFFRE}/acceptconditiondevis/:devis`} component={asyncComponent(() => import('./routes/AcceptConditionDevis'))}/>
                            <Route path={`${ROUTE_OFFRE}/listedevis`} component={asyncComponent(() => import('./routes/ListeDevis'))}/>
                            <Route path={`${ROUTE_OFFRE}/devis/:devis`} component={asyncComponent(() => import('./routes/ListeDevis/OneDevis'), {backUrl: `${ROUTE_OFFRE}/listedevis`})}/>

                            <Route path={`${ROUTE_OFFRE}/convertdevis/:devis`} component={asyncComponent(() => import('./routes/ConvertDevis'))}/>

                            <Route path={`${ROUTE_OFFRE}/addfacture`} component={asyncComponent(() => import('./routes/CreerFacture'))}/>
                            <Route path={`${ROUTE_OFFRE}/editfacture/:facture`} component={asyncComponent(() => import('./routes/EditFacture'))}/>
                            <Route path={`${ROUTE_OFFRE}/editgeneratedfacture/:facture`} component={asyncComponent(() => import('./routes/EditGeneratedFacture'))}/>
                            <Route path={`${ROUTE_OFFRE}/listefactures`} component={asyncComponent(() => import('./routes/ListeFactures'))}/>
                            <Route path={`${ROUTE_OFFRE}/facture/:facture/addpayment`} component={asyncComponent(() => import('./routes/ListeFactures/AddPayment'))}/>
                            <Route path={`${ROUTE_OFFRE}/facture/:facture/editpayment/:payment`} component={asyncComponent(() => import('./routes/ListeFactures/EditPayment'))}/>
                            <Route path={`${ROUTE_OFFRE}/facture/:facture`} component={asyncComponent(() => import('./routes/ListeFactures/OneFacture'), {backUrl: `${ROUTE_OFFRE}/listefactures`})}/>

                            <Route path={`${ROUTE_OFFRE}/editdevisforcommande/:devis`} component={asyncComponent(() => import('./routes/EditDevisForCommande'))}/>

                            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                        </Switch>
                    )
                    :
                    (
                        <Switch>
                            <Redirect exact from={`${ROUTE_OFFRE}/`} to={`${ROUTE_OFFRE}/adddevis`}/>
                            <Route path={`${ROUTE_OFFRE}/adddevis`} component={asyncComponent(() => import('./routes/CreerDevis'))}/>
                            <Route path={`${ROUTE_OFFRE}/editdevis/:devis`} component={asyncComponent(() => import('./routes/EditDevis'))}/>
                            <Route path={`${ROUTE_OFFRE}/consultdevis/:devis`} component={asyncComponent(() => import('./routes/ConsultDevis'))}/>
                            <Route path={`${ROUTE_OFFRE}/acceptconditiondevis/:devis`} component={asyncComponent(() => import('./routes/AcceptConditionDevis'))}/>
                            <Route path={`${ROUTE_OFFRE}/listedevis`} component={asyncComponent(() => import('./routes/ListeDevis'))}/>
                            <Route path={`${ROUTE_OFFRE}/devis/:devis`} component={asyncComponent(() => import('./routes/ListeDevis/OneDevis'), {backUrl: `${ROUTE_OFFRE}/listedevis`})}/>

                            <Route path={`${ROUTE_OFFRE}/editdevisforcommande/:devis`} component={asyncComponent(() => import('./routes/EditDevisForCommande'))}/>

                            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                        </Switch>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return { authUser }
};

export default connect (mapStateToProps, {}) (Offres);
