import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS_REMEMBER,
    SIGNOUT_USER_REMEMBER,
    PREMIERE_CONNECTION,
    SIGNOUT_USER_EXPIRATION,
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_USER,
    SWITCH_USER
} from 'constants/ActionTypes';

export const userSwithAgency = (credential) => {
    return {
        type: SWITCH_USER,
        payload: credential
    };
};
export const userSignIn = (user) => {
    return {
        type: SIGNIN_USER,
        payload: user
    };
};
export const premiereConnection = () => {
    return {
        type: PREMIERE_CONNECTION,
    };
};
export const userSignOut = () => {
    return {
        type: SIGNOUT_USER
    };
};
export const updateNotification = () => {
    return {
        type: UPDATE_NOTIFICATION,
    };
};

export const updateNotificationSuccess = (notification) => {
    return {
        type: UPDATE_NOTIFICATION_SUCCESS,
        payload: notification
    };
};

export const userSignOutExpiration = () => {
    return {
        type: SIGNOUT_USER_EXPIRATION
    };
};
export const userSignOutRemember = (url) => {
    return {
        type: SIGNOUT_USER_REMEMBER,
        payload: url
    };
};

export const userSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: authUser
    }
};
export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    }
};

export const updateUser = (user) => {
    return {
        type: UPDATE_USER,
        payload: user
    }
};

export const userSignOutSuccessRemember = (authUser, url) => {
    return {
        type: SIGNOUT_USER_SUCCESS_REMEMBER,
        payload: authUser,
        paryloadUrl : url
    }
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    };
};

export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};
export const showAuthLoader = () => {
    return {
        type: ON_SHOW_LOADER,
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE,
    };
};
