import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import {
    ROUTE_SECTORS
} from 'constants/ActionTypes';


const Installers = () => (

    <div className='app-wrapper'>
        <Switch>
            <Redirect exact from={`${ROUTE_SECTORS}/`} to={`${ROUTE_SECTORS}/listesectors`}/>
            <Route path={`${ROUTE_SECTORS}/addsector`} component={asyncComponent(() => import('./routes/AjouterSector'))}/>
            <Route path={`${ROUTE_SECTORS}/listesectors`} component={asyncComponent(() => import('./routes/ListeSectors'))}/>
            <Route path={`${ROUTE_SECTORS}/:idsector`} component={asyncComponent(() => import('./routes/ListeSectorsOne'))}/>
            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Installers;
