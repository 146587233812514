import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import {
    ROUTE_COMMANDE
} from 'constants/ActionTypes';

const Commandes = ({match}) => (
    <div className='app-wrapper'>
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/addcommande`}/>
            <Route path={`${ROUTE_COMMANDE}/addcommande`} component={asyncComponent(() => import('./routes/AjouterCommande'))}/>
            <Route path={`${ROUTE_COMMANDE}/listecommandes`} component={asyncComponent(() => import('./routes/ListeCommandes'))}/>
            <Route path={`${ROUTE_COMMANDE}/:commande`} component={asyncComponent(() => import('./routes/ListeCommandes/OneCommande'), {backUrl: `${ROUTE_COMMANDE}/:commande`})}/>
            <Route
                component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Commandes;