import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';

import CustomScrollbars from 'util/CustomScrollbars';
import {connect} from "react-redux";


class SidenavContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authBilling: false
        };
    }

    componentDidMount() {
        const {history} = this.props;

        const pathname = `#${history.location.pathname}`;// get current path
        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function () {
                for (let j = 0; j < menuLi.length; j++) {
                    if (menuLi[j].classList.contains('open')) {
                        menuLi[j].classList.remove('open')
                    }
                }
                this.classList.toggle('open');
            }
        }
        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (e) {

        }

        if (this.props && this.props.authUser !== undefined) {
            if (this.props.authUser.success === true) {
                if (this.props.authUser.data.role !== undefined && this.props.authUser.data.role === 'ROLE_ADMIN') {
                    this.setState({authBilling: true});
                }
            }
        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] == 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    render() {
        return (
            <CustomScrollbars className='scrollbar' style={{height: 'calc(100vh - 70px)'}}>
                <ul className='nav-menu'>

                    <li className='nav-header'>Général</li>
                    <li className='menu no-arrow'>
                        <NavLink to='/app/dashboard'>
                            <i className='zmdi zmdi-view-dashboard zmdi-hc-fw'/>
                            <span className='nav-text'>Tableau de bord</span>
                        </NavLink>
                    </li>

                    <li className='nav-header'>Clients</li>
                    <li className='menu no-arrow'>
                        <NavLink to='/app/client/addclient'>
                            <i className='zmdi zmdi-account-add zmdi-hc-fw'/>
                            <span className='nav-text'>Ajouter</span>
                        </NavLink>
                    </li>
                    <li className='menu no-arrow'>
                        <NavLink to='/app/client/listeclients'>
                            <i className='zmdi zmdi-accounts-list zmdi-hc-fw'/>
                            <span className='nav-text'>Liste clients</span>
                        </NavLink>
                    </li>

                    <li className='nav-header'>Devis</li>
                    <li className='menu no-arrow'>
                        <NavLink to='/app/offre/adddevis'>
                            <i className='zmdi zmdi-edit zmdi-hc-fw'/>
                            <span className='nav-text'>Créer un devis</span>
                        </NavLink>
                    </li>
                    <li className='menu no-arrow'>
                        <NavLink to='/app/offre/listedevis'>
                            <i className='zmdi zmdi-view-list zmdi-hc-fw'/>
                            <span className='nav-text'>Liste des devis</span>
                        </NavLink>
                    </li>

                    <li className='nav-header'>Commandes fournisseurs</li>
                    <li className='menu no-arrow'>
                        <NavLink to='/app/commande/listecommandes'>
                            <i className='zmdi zmdi-truck zmdi-hc-fw'/>
                            <span className='nav-text'>Liste des commandes</span>
                        </NavLink>
                    </li>

                    {this.state.authBilling
                        ? (
                            <div>
                                <li className='nav-header'>Factures</li>
                                <li className='menu no-arrow'>
                                    <NavLink to='/app/offre/addfacture'>
                                        <i className='zmdi zmdi-edit zmdi-hc-fw'/>
                                        <span className='nav-text'>Créer une facture</span>
                                    </NavLink>
                                </li>
                                <li className='menu no-arrow'>
                                    <NavLink to='/app/offre/listefactures'>
                                        <i className='zmdi zmdi-view-list zmdi-hc-fw'/>
                                        <span className='nav-text'>Liste des factures</span>
                                    </NavLink>
                                </li>
                            </div>
                        )
                        : null
                    }

                    <li className='nav-header'>Planning</li>
                    <li className='menu no-arrow'>
                        <NavLink to='/app/installer/listeinstallers'>
                            <i className='zmdi zmdi-assignment-account zmdi-hc-fw'/>
                            <span className='nav-text'>Liste poseurs</span>
                        </NavLink>
                    </li>
                    <li className='menu no-arrow'>
                        <NavLink to='/app/installer/planning'>
                            <i className='zmdi zmdi-calendar-note zmdi-hc-fw'/>
                            <span className='nav-text'>Planning</span>
                        </NavLink>
                    </li>

                </ul>
            </CustomScrollbars>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return { authUser }
};

export default connect (mapStateToProps, {}) (withRouter(SidenavContent));
