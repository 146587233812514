import React from 'react';
import {withRouter} from 'react-router-dom'
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {ABOVE_THE_HEADER, BELOW_THE_HEADER, COLLAPSED_DRAWER, FIXED_DRAWER, VERTICAL_NAVIGATION, ROUTE_BASE} from 'constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';

import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import TopNav from 'components/TopNav';
import Footer from 'components/Footer';
import Client from './routes/Clients'
import Installer from './routes/Installers'
import Sector from './routes/Sectors'
import Projects from './routes/Projects'
import Offre from './routes/Offres'
import Commande from './routes/Commandes'
import Utilisateur from './routes/Utilisateur'


class App extends React.Component {

    render() {
        var key = "-1";

        const {drawerType, navigationStyle, horizontalNavPosition, authUser} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

        if (authUser.data !== undefined && authUser.data !== null) {
            if (authUser.data.currentAgency !== undefined && authUser.data.currentAgency !== null) {
                key = authUser.data.currentAgency.id;
            }
        }

        console.log("key", key);

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        } else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }
        return (
            <div className={`app-container ${drawerStyle}`} key={authUser+key}>
                <Sidebar/>
                <div className='app-main-container'>
                    <div className='app-header'>
                        {(navigationStyle === VERTICAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                        <TopNav styleName='app-top-header'/>}
                        <Header/>
                        {(navigationStyle === VERTICAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                        <TopNav/>}

                    </div>

                    <main className='app-main-content-wrapper'>
                        <div className='app-main-content'>
                            <Switch>
                                <Route path={`${ROUTE_BASE}/dashboard`} component={asyncComponent(() => import('./routes/DashboardPage'))}/>
                                <Route path={`${ROUTE_BASE}/client`} component={Client}/>
                                <Route path={`${ROUTE_BASE}/installer`} component={Installer}/>
                                <Route path={`${ROUTE_BASE}/sector`} component={Sector}/>
                                <Route path={`${ROUTE_BASE}/projects`} component={Projects}/>
                                <Route path={`${ROUTE_BASE}/offre`} component={Offre}/>
                                <Route path={`${ROUTE_BASE}/commande`} component={Commande}/>
                                <Route path={`${ROUTE_BASE}/utilisateur`} component={Utilisateur}/>
                                <Route component={asyncComponent(() => import('components/Error404'))}/>
                            </Switch>
                        </div>
                        <Footer/>
                    </main>
                </div>
            </div>
        );
    }
}


const mapStateToProps = ({settings, auth}) => {
    const {authUser} = auth;
    const {drawerType, navigationStyle, horizontalNavPosition} = settings;
    return {drawerType, navigationStyle, horizontalNavPosition, authUser}
};
export default withRouter(connect(mapStateToProps)(App));
