import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import {
    ROUTE_UTILISATEUR
} from 'constants/ActionTypes';

const Commandes = ({match}) => (
    <div className='app-wrapper'>
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/profil`}/>
            <Route path={`${ROUTE_UTILISATEUR}/profil`} component={asyncComponent(() => import('./routes/Profil'))}/>
            <Route
                component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Commandes;