import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import {
    ROUTE_INSTALLER
} from 'constants/ActionTypes';


const Installers = () => (

    <div className='app-wrapper'>
        <Switch>
            <Redirect exact from={`${ROUTE_INSTALLER}/`} to={`${ROUTE_INSTALLER}/listeinstallers`}/>
            <Route path={`${ROUTE_INSTALLER}/planning`} component={asyncComponent(() => import('./routes/Planning'))}/>
            <Route path={`${ROUTE_INSTALLER}/addinstaller`} component={asyncComponent(() => import('./routes/AjouterInstaller'))}/>
            <Route path={`${ROUTE_INSTALLER}/listeinstallers`} component={asyncComponent(() => import('./routes/ListeInstallers'))}/>
            <Route path={`${ROUTE_INSTALLER}/:idinstaller`} component={asyncComponent(() => import('./routes/ListeInstallersOne'))}/>
            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Installers;