import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import {
   ROUTE_CLIENT
} from 'constants/ActionTypes';


const Clients = () => (

    <div className='app-wrapper'>
        <Switch>
            <Redirect exact from={`${ROUTE_CLIENT}/`} to={`${ROUTE_CLIENT}/addclient`}/>
            <Route path={`${ROUTE_CLIENT}/addclient`} component={asyncComponent(() => import('./routes/AjouterClient'))}/>
            <Route path={`${ROUTE_CLIENT}/listeclients`} component={asyncComponent(() => import('./routes/ListeClients'))}/>
            <Route path={`${ROUTE_CLIENT}/:idclient`} component={asyncComponent(() => import('./routes/ListeClientsOne'))}/>
            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Clients;