import React from 'react';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import configureStore, { history } from './store';
import App from './containers/App';
import { ToastContainer, toast } from 'react-toastify';
import CacheBuster from "./CacheBuster";


export const store = configureStore();

const MainApp = () =>

    <Provider store={store}>
        <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }

                return (
                    <div className='d-flex w-100'>
                        <ConnectedRouter history={history}>
                            <Switch>
                                <Route path='/' component={App} />
                            </Switch>
                        </ConnectedRouter>
                        <ToastContainer />
                    </div>
                );
            }}
        </CacheBuster>
    </Provider>





export default MainApp;